<template>
  <v-container>
    <PageHeader :pageTitle="pageTitle" :pageDescription="pageDescription" />

    <v-row v-if="readPerm">
      <v-col v-if="data">
        <v-card tile>
          <v-list dense>
            <v-subheader>Kategorien</v-subheader>
            <v-list-item-group v-model="data.rootCategory" color="primary">
              <v-list-item
                v-for="item in data.rootCategory"
                :key="item.id"
                @click="openCategory(item.uuid)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col v-else>
        <p>{{ $t("plugins.frontend.createFrontendMessage") }}</p>
        <v-btn
          depressed
          color="green"
          class="white--text"
          @click="safeguardingDialog = true"
          :disabled="disabledByPermission(writePerm)"
        >
          {{ $t("plugins.frontend.createFrontendButton") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col v-html="$t('phrases.noPermissionsMessage')"></v-col>
    </v-row>

    <AreYouSureDialog
      :title="this.$t('plugins.frontend.createFrontendButton')"
      :text="this.$t('plugins.frontend.createFrontendOptIn')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
export default {
  name: "RootView",

  components: {
    PageHeader,
    AreYouSureDialog,
  },

  data() {
    return {
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: this.$t("plugins.frontend.name"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
    };
  },

  props: [],

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  methods: {
    openCategory(uuid) {
      this.$router.push({
        name: "frontend.category.:id",
        params: { id: uuid },
      });
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.createFrontend();
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(`company/${this.companyID}/frontend`); // await data from mixin

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;
      } else if (response.status === 403) {
        // no permission
      }
    },

    emittedTableEvent(obj) {
      if (obj.action === "editItem") {
        this.$router.push({
          name: "plugins.documentmaker.:id",
          params: { id: obj.itemUuid },
        });
      } else if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    async createFrontend() {
      let response = await this.post(`company/${this.companyID}/frontend`); // await data from mixin

      let newItem = response.data.data.result[0];
      this.items.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Das Dokument wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>
